var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"slider-container"}},[_c('div',{attrs:{"id":"arrows"}},[_c('div',{attrs:{"id":"left-arrow"},on:{"click":function($event){return _vm.changeSlide(-1)}}}),_c('div',{attrs:{"id":"right-arrow"},on:{"click":function($event){return _vm.changeSlide(+1)}}})]),_c('div',{attrs:{"id":"carousel-container"}},[_c('div',{staticClass:"carousel-item",class:{
        active: _vm.slideId === 0,
        fourth: _vm.slideId === 1,
        third: _vm.slideId === 2,
        second: _vm.slideId === 3,
      },on:{"click":function($event){return _vm.onClick(0)}}},[_c('img',{attrs:{"src":require("@/assets/370.png"),"alt":""}})]),_c('div',{staticClass:"carousel-item",class:{
        active: _vm.slideId === 1,
        fourth: _vm.slideId === 2,
        third: _vm.slideId === 3,
        second: _vm.slideId === 0,
      },on:{"click":function($event){return _vm.onClick(1)}}},[_c('img',{attrs:{"src":require("@/assets/400.png"),"alt":""}})]),_c('div',{staticClass:"carousel-item",class:{
        active: _vm.slideId === 2,
        fourth: _vm.slideId === 3,
        third: _vm.slideId === 0,
        second: _vm.slideId === 1,
      },on:{"click":function($event){return _vm.onClick(2)}}},[_c('img',{attrs:{"src":require("@/assets/410.png"),"alt":""}})]),_c('div',{staticClass:"carousel-item",class:{
        active: _vm.slideId === 3,
        fourth: _vm.slideId === 0,
        third: _vm.slideId === 1,
        second: _vm.slideId === 2,
      },on:{"click":function($event){return _vm.onClick(3)}}},[_c('img',{attrs:{"src":require("@/assets/420.png"),"alt":""}})])]),_c('div',{staticClass:"slider-inner"},[_c('div',{attrs:{"id":"slider-content"}},[_c('h2',{attrs:{"id":"slide-title"}},[_vm._v(" "+_vm._s(_vm.$t("a370-title"))+" "),_c('br'),_c('span',{staticClass:"corsivo"},[_vm._v(_vm._s(_vm.$t("a370-subtitle")))]),_c('router-link',{attrs:{"to":"a370"}},[_c('button',[_vm._v(_vm._s(_vm.$t("btn-learn-more")))])])],1),_c('span',{staticClass:"hide",attrs:{"data-slide-title":"1"}},[_vm._v(_vm._s(_vm.$t("a400-title"))+" "),_c('br'),_c('span',{staticClass:"corsivo"},[_vm._v(_vm._s(_vm.$t("a400-subtitle")))]),_c('router-link',{attrs:{"to":"a400"}},[_c('button',[_vm._v(_vm._s(_vm.$t("btn-learn-more")))])])],1),_c('span',{staticClass:"hide",attrs:{"data-slide-title":"2"}},[_vm._v(_vm._s(_vm.$t("a410-title"))+" "),_c('br'),_c('span',{staticClass:"corsivo"},[_vm._v(_vm._s(_vm.$t("a410-subtitle")))]),_c('router-link',{attrs:{"to":"a410"}},[_c('button',[_vm._v(_vm._s(_vm.$t("btn-learn-more")))])])],1),_c('span',{staticClass:"hide",attrs:{"data-slide-title":"3"}},[_vm._v(_vm._s(_vm.$t("a420-title"))+" "),_c('br'),_c('span',{staticClass:"corsivo"},[_vm._v(_vm._s(_vm.$t("a420-subtitle")))]),_c('router-link',{attrs:{"to":"a420"}},[_c('button',[_vm._v(_vm._s(_vm.$t("btn-learn-more")))])])],1),_c('span',{staticClass:"hide",attrs:{"data-slide-title":"0"}},[_vm._v(_vm._s(_vm.$t("a370-title"))+" "),_c('br'),_c('span',{staticClass:"corsivo"},[_vm._v(_vm._s(_vm.$t("a370-subtitle")))]),_c('router-link',{attrs:{"to":"a370"}},[_c('button',[_vm._v(_vm._s(_vm.$t("btn-learn-more")))])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }