<template>
  <div class="upgrade-kit" v-if="!$store.state.loading">
    <div class="cover full" data-aos="fade-up" data-aos-duration="1000">
      <img :src="images[0]" alt="CarboBrake Upgrade Kit"/>
    </div>
    <h1 data-aos="zoom-in" data-aos-duration="1000" v-html="$t('upgrade-kit-title')"></h1>

    <div class="section">
      <div
        class="section-title side"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('upgrade-kit-section1-title')"
      ></div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('upgrade-kit-section1-paragraph')"
      ></div>
    </div>

    <div class="zoom-image-container" id="nissan_gt_r">
      <img :src="images[1]" alt="CarboBrake Nissan GT-R"/>
    </div>

    <div class="section">
      <div
        class="section-title side"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('upgrade-kit-section2-title')"
      ></div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('upgrade-kit-section2-paragraph')"
      ></div>
    </div>

    <theta-carousel> </theta-carousel>
  </div>
</template>

<script>
import ThetaCarousel from '../components/ThetaCarousel.vue'
export default {
  components: {
    ThetaCarousel
  },

  data() {
    return {
      images: [
        require('../assets/upgrade-kit_cover.jpg'),
        require('../assets/nissan-gt-r-case.jpg')
      ]
    }
  },

  mounted() {
    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch('LOAD_IMAGES', this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setFooterVisible')

          //simulate resize for scrolling transitions
          let event
          if (typeof Event === 'function') {
            event = new Event('resize')
          } else {
            //IE
            event = document.createEvent('Event')
            event.initEvent('resize', true, true)
          }
          window.dispatchEvent(event)
        }, 1100)
        //animazioni della pagina
        //SCROLLMAGIC
        const sceneNissan = this.$scrollmagic
          .scene({
            // ID of element where animation starts
            triggerElement: '#nissan_gt_r',

            // {0,0.5,1} - animations starts from {top,center,end} of window
            triggerHook: 0,

            offset: -60
          })

          // Declaration of animation and attaching to element
          .setTween('#nissan_gt_r img', 0.8, {
            scale: 1
          })

        // Helpful tags for orientation on the screen
        //.addIndicators({ name: 'cooling (duration: 300)' })

        // add scenes to window scrollmagic controller
        this.$scrollmagic.addScene(sceneNissan)
      })
    })
  }
}
</script>

<style lang="scss"></style>
